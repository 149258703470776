import React from "react";
import Background from "../components/Background";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";


function TermsOfService() {
  return (
    <>
      <div className="flex flex-col w-full min-h-screen">
        <Navbar />
        <div className="grow">
          <Background styling="flex justify-items-center w-full p-20 grid items-center">
            <h1 className="text-6xl text-center font-bold text-white">
              Terms of service
            </h1>
          </Background>
          <div className="grid justify-items-center">
            <div className="max-w-5xl px-8 py-12">
              <div className="prose prose-lg">
                <h2 class="font-bold my-5 text-3xl text-gray-700">1. File Submission</h2>
                <p class="ml-8 text-gray-500 text-xl">You agree to only submit files that you are legally allowed to possess, and that do not violate any laws or infringe on rights of others. You are responsible for ensuring that the files do not contain personal data or confidential information.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">2. Storage of Files</h2>
                <p class="ml-8 text-gray-500 text-xl">Files will be stored on the malprob's cloud infrastructure, and may be kept for an indefinite period of time.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">3. Restrictions on Use</h2>
                <p class="ml-8 text-gray-500 text-xl">You agree not to use the Service in any way that breaches laws or regulations, or for illegal activities. You also agree not to reproduce or distribute any materials from the Service.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">4. Liability</h2>
                <p class="ml-8 text-gray-500 text-xl">Nucleon-Security SAS will not be liable for any damages or misuse of the Service.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">5. Privacy Policy</h2>
                <p class="ml-8 text-gray-500 text-xl">Use of the Service implies acceptance of the Privacy Policy on the malprob.io website, which explains how the company collects, uses, and shares personal information provided by the User.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">6. User Representation</h2>
                <p class="ml-8 text-gray-500 text-xl">If using the Service on behalf of a company, you represent that you have the authority to bind that business to these terms.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">7. Termination</h2>
                <p class="ml-8 text-gray-500 text-xl">Users who engage in illegal activities or misuse the service may have their account cancelled and be excluded from using the Service.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">8. File rights</h2>
                <p class="ml-8 text-gray-500 text-xl">By submitting files to the Service, you grant Nucleon-Security SAS a non-exclusive, worldwide, and perpetual license to use, copy, modify, and distribute the files for the purpose of providing the Service. However, Nucleon-Security SAS does not claim ownership of the files and you retain all rights to the files that you submit.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">9. Governing Law</h2>
                <p class="ml-8 text-gray-500 text-xl">These terms of service and any disputes arising out of or related to the Service shall be governed by and construed in accordance with the laws of France.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">10. Entire Agreement</h2>
                <p class="ml-8 text-gray-500 text-xl">These terms of service constitute the entire agreement between you and Nucleon-Security SAS regarding the use of the Service, and supersedes any prior agreements or understandings, whether written or oral, regarding the use of the Service.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}


export default TermsOfService;
