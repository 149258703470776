import React, { useContext, useState } from "react";
import Header from "../components/Header";
import { signUpFields } from "../resources/formFields";
import Input from "../components/Input";
import Submit from "../components/Submit";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import Error from "../components/Error";
import jwt_decode from "jwt-decode";
import AuthContext from "../context/AuthContext";
import "../css/background.css";

const baseURL = process.env.REACT_APP_BACKEND_URL;

const fields = signUpFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

function SignUp() {
  const { setRemember, setLoginError, setToken, setUser } =
    useContext(AuthContext);
  const [loginState, setLoginState] = useState(fieldsState);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  const register = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("email", loginState["email"]);
    formData.append("username", loginState["username"]);
    formData.append("password", loginState["password"]);

    setLoad(true);

    axios
      .post(`${baseURL}/register/`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTimeout(() => {
          axios
            .post(
              `${baseURL}/token/`,
              JSON.stringify({
                email: loginState["email"],
                password: loginState["password"],
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              setRemember(false);
              setLoginError(true);
              setToken(response.data);
              setUser(jwt_decode(response.data.access));
              setLoad(false);
              navigate("/");
            })
            .catch((response) => {
              setLoad(false);
              setLoginError(true);
            });
          setLoad(false);
        }, 1000);
      })
      .catch((response) => {
        setLoad(false);
        setError(true);

        if (response.response.data.email) {
          setTitle("Email Error");
          setMessage("This email is already used");
        } else if (response.response.data.username) {
          setTitle("Username Error");
          setMessage(response.response.data.username);
        } else if (response.response.data.password) {
          setTitle("Password Error");
          setMessage(response.response.data.password[0]);
        } else {
          setTitle("Sign Up error");
          setMessage("An error has occured!");
        }
      });
  };

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  return (
    <>
      {load && <Spinner />}
      {error && (
        <Error message={message} title={title} close={() => setError(false)} />
      )}
      <div
        className="block lg:grid w-screen min-h-screen"
        style={{ gridTemplateColumns: "3fr 5fr" }}
      >
        <div className="min-h-screen h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="bg-white max-w-lg w-full space-y-8 p-10 rounded-3xl">
            <Header>
              <h1 className="text-left text-2xl font-bold text-gray-700 mb-3">
                Create a new Account
              </h1>
            </Header>
            <form className="space-y-6" onSubmit={register}>
              <div className="flex-row mb-10">
                {fields.map((field) => (
                  <Input
                    key={field.id}
                    handleChange={handleChange}
                    value={loginState[field.id]}
                    labelText={field.labelText}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={field.placeholder}
                  />
                ))}
              </div>
              <Submit text="Sign Up" className="mt-0" />
            </form>
            <p
              className="text-sm text-gray-500"
              style={{ marginBlockStart: "10px" }}
            >
              Already have an account yet?
              <Link
                onClick={() => {
                  setLoginError(false);
                }}
                to="/signin"
                className="ml-1 font-medium text-nucleon-800 hover:text-nucleon-800"
              >
                Sign In
              </Link>
            </p>
          </div>
        </div>
        <div
          className="grid"
          style={{
            background: `url(
              ${"https://images.unsplash.com/photo-1643135795004-fc2234a62549?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80"}
            )`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="bg w-full h-full">
            <div className="w-full h-full p-12 side text-white grid place-content-center text-center">
              <div className="content grid justify-items-center">
                <h1 className="text-6xl font-bold mb-4">
                  PROACTIVE <br />{" "}
                  <span className="text-nucleon-100">ENDPOINT</span> <br />{" "}
                  PROTECTION
                </h1>
                <p className="text-lg opacity-75 mb-4">
                  AI and Zero-trust powered EDR to stay <br /> ahead of
                  cybercriminals
                </p>
                <a
                  target="_blank"
                  href="https://nucleon-security.com/"
                  className="w-fit bg-nucleon-100/90 drop-shadow-sm text-white text-sm font-semibold rounded-md focus:ring-nucleon-100 focus:border-nucleon-100 block py-3.5 px-7 hover:bg-nucleon-100"
                >
                  More about Nucleon
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
