import React from "react";
import { Link } from "react-router-dom";

function Header(props) {
  return (
    <div className="flex flex-col w-full gap-2.5  justify-between">
      <Link to="/">
        <img
          className="h-12 w-auto mb-2"
          src="images/malprob.png"
          alt="nucleon"
        />
      </Link>

      <div>{props.children}</div>
    </div>
  );
}

export default Header;
