import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Search from "../components/Search";
import FileDropzone from "../components/FileDropzone";
import Footer from "../components/Footer";
import Background from "../components/Background";
import Error from "../components/Error";
import Warning from "../components/Warning";
import Success from "../components/Success";
import PopUp from "../components/PopUp";

function Home() {
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState(false);
  const [message, setMessage] = useState(false);

  return (
    <>
      {popUp && <PopUp close={() => setPopUp(false)} />}
      {error && (
        <Error title={title} message={message} close={() => setError(false)} />
      )}
      {success && (
        <Success
          title="File uploaded Successfully"
          message="File is waiting to be analyzed"
          close={() => setSuccess(false)}
        />
      )}
      {warning && (
        <Warning
          title="API calls limit"
          message="Concider sign in for a permium plan for unlimited number of requests"
          close={() => setWarning(false)}
        />
      )}
      <div className="">
        <div className="min-h-screen">
          <div className="flex flex-col w-full min-h-screen">
            <Navbar />
            <Background styling="grow grid items-center px-10 py-20 md:p-20">
              <div className="grid h-fit gap-10">
                <FileDropzone
                  setWarning={setWarning}
                  setError={setError}
                  setPopUp={setPopUp}
                  setTitle={setTitle}
                  setMessage={setMessage}
                  setSuccess={setSuccess}
                />
                <Search />
              </div>
            </Background>
          <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
