import React from "react";
import notFound404 from "../resources/notFound404.svg";
import { Link } from "react-router-dom";
import Background from "../components/Background";

function NotFound() {
  return (
    <Background className="w-screen h-screen">
      <div className="grid place-items-center h-screen">
        <div className="grid justify-items-center gap-5">
          <h1 className="mb-5 text-4xl lg:text-6xl text-white">
            {" "}
            <span className="font-bold text-nucleon-900">404</span> Page not
            found
          </h1>

          <img className="h-96" src={notFound404} alt="not found" />

          <Link
            to="/"
            className="flex items-center text-lg py-2 px-6 mt-5 bg-white hover:bg-gray-200 rounded-xl text-nucleon-1100"
          >
            Return to home
          </Link>
        </div>
      </div>
    </Background>
  );
}

export default NotFound;
