import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";

export default function Input({
  handleChange,
  value,
  labelText,
  labelFor,
  id,
  name,
  type,
  isRequired = false,
  placeholder,
  customClass,
}) {
  const { loginError } = useContext(AuthContext);
  return (
    <div className="my-5">
      <label htmlFor={labelFor} className="text-md text-gray-500">
        {labelText}
      </label>
      <input
        onChange={handleChange}
        value={value}
        id={id}
        name={name}
        type={type}
        required={isRequired}
        className={
          "block mt-1.5 appearance-none w-full py-2 border-b-2 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 " +
          (loginError ? "border-red-500 " : "border-gray-300 ") +
          customClass
        }
        placeholder={placeholder}
      />
    </div>
  );
}
