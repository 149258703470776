import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import advanced_ai from "../resources/advanced_ai.jpg";
import complete_automation from "../resources/complete_automation.jpg";
import seamless_connectivity from "../resources/seamless_connectivity.jpg";
import Cards from "../components/Cards";
import "../css/about.css";

function About() {
  return (
    <>
      <div className="flex flex-col w-full min-h-screen">
        <Navbar />
        <div className="grow">
          <div>
            <div className="max-w-5xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
              <div className="text-center">
                <h2 className="text-base font-semibold text-gray-500 tracking-wide uppercase">
                  About
                </h2>
                <h2 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                  <span className="text-nucleon-700">Nucleon </span> Malprob
                </h2>
                <p className="max-w-5xl text-md mt-5 mx-auto md:text-2xl text-gray-500">
                  Malprob is a leading malware detection and identification
                  service, powered by cutting-edge AI technology. Our platform
                  seamlessly connects to application programming interfaces
                  (APIs) to deliver unparalleled cybersecurity to our clients.
                  With the inclusion of the Malprob model in the{" "}
                  <a
                    className="opacity-80 hover:opacity-100 text-nucleon-800 font-semibold"
                    href="https://nucleon-security.com/nucleon-endpoint-detection-response-and-remediation-edr/"
                    target="_blank"
                  >
                    Nucleon Smart Endpoint
                  </a>{" "}
                  EDR, our clients benefit from the powerful combination of
                  Malprob's detection capabilities and the direct protection of
                  their endpoints.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <Cards />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
