import React from "react";
import Background from "../components/Background";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function PrivacyPolicy() {
  return (
    <>
      <div className="flex flex-col w-full min-h-screen">
        <Navbar />
        <div className="grow">
          <Background styling="flex justify-items-center w-full p-20 grid items-center">
            <h1 className="text-6xl text-center font-bold text-white">
              Privacy policy
            </h1>
          </Background>
          <div className="grid justify-items-center">
            <div className="max-w-5xl px-8 py-12">
              <div className="prose prose-lg">
                <p className="text-gray-500 text-xl">
                  This privacy policy governs the collection, use, and disclosure of personal information by Nucleon-Security SAS, the company that owns and operates the malprob.io online platform (hereafter malprob or Service). By using the Service, you consent to the collection, use, and disclosure of your personal information as described in this privacy policy.
                </p>
                <h2 class="font-bold my-5 text-3xl text-gray-700">Information Collection</h2>
                <p class="text-gray-500 text-xl">We may collect personal information from you, including but not limited to your name, email address, and IP address. We may also collect information about the files that you submit to the Service, including but not limited to the file name and file content.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">Use of Information</h2>
                <p class="text-gray-500 text-xl">We may use your personal information to provide and improve the Service, to communicate with you, and to comply with legal obligations. We may also use the information about the files that you submit to the Service for the purpose of providing the Service.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">Disclosure of Information</h2>
                <p class="text-gray-500 text-xl">We may disclose your personal information to third parties for the purpose of providing the Service, complying with legal obligations, or enforcing our policies. We may also share non-personal information and aggregate data with third parties for analytical purposes.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">Security</h2>
                <p class="text-gray-500 text-xl">We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet, or method of electronic storage, is 100% secure.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">Cookies</h2>
                <p class="text-gray-500 text-xl">We may use cookies and similar technologies to improve your user experience and the overall quality of the Service. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the Service may become inaccessible or not function properly.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">Changes to this Policy</h2>
                <p class="text-gray-500 text-xl">We may update this privacy policy from time to time, and we encourage you to review it periodically. Your continued use of the Service after we post any modifications to the privacy policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified privacy policy.</p>

                <h2 class="font-bold my-5 text-3xl text-gray-700">Contact Us</h2>
                <p class="text-gray-500 text-xl">If you have any questions or concerns about this privacy policy or our practices, please contact us at team@nucleon-security.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
