import React from "react";
import "../css/background.css";

function Background(props) {
  return (
      <div className={"gradiant " + props.styling}>{props.children}</div>
  );
}

export default Background;
