import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import Error from "./Error";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import Background from "./Background";
import arrow from "../resources/arrow.svg";

function Search() {
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const fileSearch = (event) => {
    event.preventDefault();

    if (![64, 40, 32].includes(event.target.hashcode.value.length)) {
      setError(true);
    } else {
      navigate(`/report/${event.target.hashcode.value}`);
    }
  };

  return (
    <>
      {error && (
        <Error
          title="Hashcode not supported"
          message="Concider using MD5, SHA1, SHA256 hashcodes"
          close={() => setError(false)}
        />
      )}
      <>
        <div className="grid items-center">
          <div className="grid gap-2.5">
            <p className="text-md lg:text-xl text-center text-gray-200 mb-2.5">
              Search for a file using MD5, SHA1 and SHA256
            </p>
            <form
              className="flex w-full justify-items-center justify-around"
              onSubmit={fileSearch}
            >
              <label htmlFor="default-search" className="sr-only">
                Search
              </label>
              <div className="flex w-full max-w-lg">
                <input
                  type="search"
                  name="hashcode"
                  id="default-search"
                  className={`block w-full max-w-3xl text-sm p-3 px-4 rounded-l-md text-gray-900 bg-white border focus:ring-nucleon-500 focus:border-nucleon-500 outline-none  "border-red-300" ${
                    error && "border-red-500"
                  }`}
                  placeholder="85eaa300997c748dbfa656454b858ae4e5f5bf7..."
                  required
                />
                <button
                  type="submit"
                  className="text-white text-xl right-1.5 bottom-1.5 bg-nucleon-800 hover:bg-nucleon-700 focus:ring-4 focus:outline-none focus:ring-gray-500 font-medium px-4 rounded-r-md"
                >
                  <FaSearch className="text-white" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
}

export default Search;
