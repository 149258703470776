import React from "react";
import { Link } from "react-router-dom";

function PopUp({ close, upgrade }) {
  return (
    <>
      <div className="absolute w-screen h-screen bg-black/25 grid place-items-center z-50 p-20">
        <div
          onClick={close}
          className="absolute w-screen h-screen bg-black/25 grid place-items-center p-20"
        ></div>
        {upgrade ? (
          <div className="relative flex flex-col bg-white items-center justify-items-center justify-between p-10 w-full max-w-lg rounded-xl z-50">
            <h1 className="text-2xl text-center font-semibold">
              Download isn't vailable in your current plan
            </h1>
            <p className="text-center my-5 opacity-75">
              Check other plans for more features
            </p>
            <Link
              to="/pricing"
              className="py-2 px-7 border border-transparent font-semibold text-white bg-nucleon-800/90 hover:bg-nucleon-800 focus:outline-none focus:ring-1 focus:ring-offset-1"
            >
              Pricing
            </Link>
          </div>
        ) : (
          <div className="relative bg-white items-center justify-between p-10 w-full max-w-lg rounded-xl z-50">
            <h1 className="text-2xl text-center font-semibold">
              You have to sign in to scan files
            </h1>
            <p className="text-center my-5 opacity-75">
              If you don't have an account concider creating a new account for
              free and check the premium plans for better experience and
              unlimited scans
            </p>
            <div className="flex w-full gap-5 justify-around xl:mt-7 text-sm xl:text-md text-center">
              <Link
                to="/signin"
                className="w-full  py-2 px-4 border border-transparent font-semibold text-white bg-nucleon-700 hover:bg-nucleon-800 focus:outline-none focus:ring-1 focus:ring-offset-1"
              >
                Sign in
              </Link>
              <Link
                to="/signup"
                className="w-full  py-2 px-4 border border-transparent font-semibold text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-offset-1"
              >
                Sign up
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PopUp;
