import React, { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL;
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    let token = sessionStorage.getItem("authTokens")
      ? jwt_decode(sessionStorage.getItem("authTokens"))
      : null;
    if (!token)
      return localStorage.getItem("authTokens")
        ? jwt_decode(localStorage.getItem("authTokens"))
        : null;

    return token;
  });
  const [token, setToken] = useState(() => {
    let token = sessionStorage.getItem("authTokens")
      ? JSON.parse(sessionStorage.getItem("authTokens"))
      : null;
    if (!token)
      return localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null;

    return token;
  });
  const [loading, setLoading] = useState(true);
  const [remember, setRemember] = useState(true);
  const [loginError, setLoginError] = useState(false);
  const [load, setLoad] = useState(false);

  const navigate = useNavigate();

  const loginUser = (event) => {
    event.preventDefault();
    setLoad(true);

    axios
      .post(
        `${baseURL}/token/`,
        JSON.stringify({
          email: event.target.email.value,
          password: event.target.password.value,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setRemember(event.target.remember.checked);
        setLoginError(true);
        setToken(response.data);
        setUser(jwt_decode(response.data.access));
        event.target.remember.checked
          ? localStorage.setItem("authTokens", JSON.stringify(response.data))
          : sessionStorage.setItem("authTokens", JSON.stringify(response.data));
        setLoad(false);
        navigate("/");
      })
      .catch((response) => {
        setLoad(false);
        setLoginError(true);
      });
  };

  const updateToken = (callback) => {
    if (loading) {
      setLoading(false);
      callback(false);
    } else if (token) {
      axios
        .post(
          `${baseURL}/token/refresh/`,
          {
            refresh: token?.refresh,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setToken(response.data);
            setUser(jwt_decode(response.data.access));
            remember
              ? localStorage.setItem(
                  "authTokens",

                  JSON.stringify(response.data)
                )
              : sessionStorage.setItem(
                  "authTokens",

                  JSON.stringify(response.data)
                );

            callback(true, response.data);
          } else {
            logoutUser();
            callback(false);
          }
        });
    }
  };

  const logoutUser = () => {
    setLoginError(false);
    setToken(null);
    setUser(null);
    remember && localStorage.removeItem("authTokens");
    remember && sessionStorage.removeItem("authTokens");
    navigate("/signin");
  };

  const contextData = {
    loginUser: loginUser,
    logoutUser: logoutUser,
    setToken: setToken,
    setRemember: setRemember,
    setLoginError: setLoginError,
    setUser: setUser,
    load: load,
    token: token,
    user: user,
    loading: loading,
    remember: setRemember,
    loginError: loginError,
    updateToken: updateToken,
  };

  useEffect(() => {
    if (loading) {
      updateToken((_) => {});
      setLoading(false);
    }
    let interval = setInterval(() => {
      if (token) {
        updateToken((_) => {});
      }
    }, 1000 * 60 * 59 * 4);

    return () => clearInterval(interval);
  }, [loading, token]);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
