import React from "react";

function Submit({ type = "Button", action = "submit", text, color }) {
  const background = color ? color : " bg-nucleon-800/90 hover:bg-nucleon-800  focus:ring-nucleon-500"
  return (
    <>
      {type === "Button" ? (
        <input
          type={action}
          value={text}
          className={
            "group relative text-sm w-full flex justify-center py-2 px-4 border border-transparent font-semibold rounded-md text-white focus:outline-none focus:ring-1 focus:ring-offset-1 " +
            background
          }
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Submit;
