import React, { useCallback, useState, useEffect } from "react";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import { useContext } from "react";
import { MdInsertDriveFile } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "../css/dropzone.css";
import Dropzone from "./Dropzone";
import { useNavigate } from "react-router-dom";

const baseURL = process.env.REACT_APP_BACKEND_URL;

function FileDropzone({
  setWarning,
  setError,
  setPopUp,
  setTitle,
  setMessage,
  setSuccess,
}) {
  const { token, updateToken, user } = useContext(AuthContext);
  const [file, setFile] = useState(undefined);
  const [isPrivate, setIsprivate] = useState(false);

  const navigate = useNavigate();

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setError(false);
      setFile(acceptedFiles[0]);
    } else if (rejectedFiles.length > 0) {
      setError(true);
      setTitle("File error");
      setMessage("File size exceeds size limit!");
    }
  }, []);

  const uploadFile = (accessToken) => {
    if (!accessToken) {
      setPopUp(true);
      setError(false);
      setSuccess(false);
      setWarning(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    formData.append("private", isPrivate);

    axios
      .post(`${baseURL}/scan/`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken.access}`,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setFile(undefined);
          setSuccess(true);
          setError(false);
          setWarning(false);
          setPopUp(false);

          navigate(`/report/${response.data["sha256"]}`);
        } else if (response.status == 204) {
          setWarning(true);
          setError(false);
          setSuccess(false);
        }
      })
      .catch((response) => {
        if (response.response.status === 400) {
          setError(true);
          setSuccess(false);
          setWarning(false);
          setPopUp(false);
          setTitle("File error");
          setMessage(response.response.data);
        }
        if (response.response.status === 401) {
          updateToken((success, newToken) => {
            if (success) {
              uploadFile(newToken);
            } else {
              navigate("/signin");
            }
          });
        }
        setFile(undefined);
      });
  };

  // Auto upload file on push
  useEffect(() => {
    if (file) uploadFile(token);
  }, [file]);

  return (
    <>
      <div className="grid items-center">
        <div className="grid gap-5 h-fit">
          <h1 className="text-5xl lg:text-6xl text-center text-gray-200">
            File <span className="font-bold text-white">Analysis</span>
          </h1>
          <p className="text-md lg:text-xl text-center text-gray-200 mb-2.5">
            Scan a file using Nucleon Malprob model
          </p>
          <div className="flex flex-col justify-items-center">
            <div className="grid justify-items-center gap-5 w-full">
              <Dropzone onDrop={onDrop} />
              {file && (
                <div className="flex items-center justify-between p-2.5 border-2 border-white rounded-md w-full max-w-lg bg-white/20 text-white">
                  <div
                    className="grid items-center gap-2 w-full"
                    style={{ gridTemplateColumns: "auto 1fr auto" }}
                  >
                    <MdInsertDriveFile className="text-xl" />
                    <p className="truncate">{file.name}</p>
                    {/*<MdRemoveCircle
                      onClick={() => setFile(undefined)}
                      className="text-gray-100 hover:text-white"
                    />*/}
                    <AiOutlineLoading3Quarters
                      style={{ animation: "spin infinite 1s linear" }}
                    />
                  </div>
                </div>
              )}
              <form
                className="flex flex-col gap-5 w-full items-center"
                onSubmit={() => uploadFile(token)}
              >
                {user && user.plan != "FREE" && (
                  <div className="flex items-center">
                    <input
                      id="private"
                      name="private"
                      type="checkbox"
                      className="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded"
                      value={isPrivate}
                      onChange={() => setIsprivate(!isPrivate)}
                    />
                    <label
                      htmlFor="private"
                      className="ml-2 block text-sm text-white"
                    >
                      Keep it private
                    </label>
                  </div>
                )}

                {/*<input
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent font-semibold max-w-sm rounded-md text-nucleon-800 bg-white hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-nucleon-300"
                  value="Scan file"
                />*/}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FileDropzone;
