import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import AuthContext from "../context/AuthContext";
import { BsLinkedin, BsFacebook, BsTwitter } from "react-icons/bs";

const social = [
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/nucleon-security/",
    icon: BsLinkedin,
  },
  {
    name: "Twitter",
    href: "https://twitter.com/NucleonSec",
    icon: BsTwitter,
  },
  {
    name: "Facebook",
    href: "https://www.facebook.com/people/Nucleon-Security/100063774541508/",
    icon: BsFacebook,
  },
];

function Footer() {
  const { token } = useContext(AuthContext);

  return (
    <footer className="grid justify-items-center px-10 lg:px-5 pb-5 pt-10 bg-white snap-end border-t-2 border-t-gray-200">
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 max-w-7xl">
        <img src="/images/malprob.png" className="h-14" alt="logo malprob" />
        <div className="text-black">
          <h4 className="font-semibold text-lg">Nucleon Malprob</h4>
          <Link to="/" className="block opacity-50 hover:opacity-100 my-1">
            Home
          </Link>
          <Link
            to="/pricing"
            className="block opacity-50 hover:opacity-100 my-1"
          >
            Pricing
          </Link>
          <Link to="/openapi" className="block opacity-50 hover:opacity-100 my-1">
            API
          </Link>
          {token && (
            <Link
              to="/account"
              className="block opacity-50 hover:opacity-100 my-1"
            >
              Account
            </Link>
          )}
          <Link to="/about" className="block opacity-50 hover:opacity-100 my-1">
            About
          </Link>
        </div>
        <div className="text-black">
          <h4 className="font-semibold text-lg">Nucleon Security</h4>
          <a
            href="https://nucleon-security.com/nucleon-endpoint-detection-response-and-remediation-edr/"
            className="block opacity-50 hover:opacity-100 my-1"
            target="_blank"
          >
            Platform
          </a>
          <a
            href="https://nucleon-security.com/services/"
            className="block opacity-50 hover:opacity-100 my-1"
            target="_blank"
          >
            Services
          </a>
        </div>
        <div className="text-black">
          <h4 className="font-semibold text-lg">Contact</h4>
          {/*<a href="#" className="flex items-center  my-1">
            <MdPermPhoneMsg className="text-nucleon-800 text-2xl mr-2.5" />{" "}
            <span className="opacity-50 hover:opacity-100">123 456 789</span>
          </a>*/}
          <a
            href="mailto:team@nucleon-security.com"
            className="my-1"
          >
            <span className="block opacity-50 hover:opacity-100">
              team@nucleon-security.com
            </span>
            <span className="opacity-50 hover:opacity-100">
              support@nucleon-security.com
            </span>
          </a>
        </div>
      </div>
      <hr className="w-full mt-10 border-gray-400 max-w-7xl" />
      <div
        className="w-full flex flex-col gap-10 md:grid md:items-center mx-auto pt-8 pb-5 overflow-hidden max-w-7xl"
        style={{ gridTemplateColumns: "1fr auto 1fr" }}
      >
        <p className="justify-self-start text-gray-400">
          &copy; 2023{" "}
          <a
            href="https://nucleon-security.com/"
            alt="Nucleon security website"
          >
            Nucleon Security
          </a>
          , All rights reserved.
        </p>

        <div className="flex md:justify-center space-x-6">
          {social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-nucleon-700"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="flex gap-10 justify-self-end text-gray-400">
          <a href="/privacy" className="hover:text-nucleon-700">
            Privacy policy
          </a>

          <a href="/terms" className="hover:text-nucleon-700">
            Terms of Service
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
