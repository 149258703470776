import React from "react";
import { useDropzone } from "react-dropzone";
import { RiUploadCloud2Line } from "react-icons/ri";
import "../css/dropzone.css";

const Dropzone = ({ onDrop, open }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 2e7,
    onDrop,
  });

  
  return (
    <div className="grid justify-items-center gap-2.5 w-full">
      <div className="container-dropzone w-full max-w-lg rounded-xl">
        <div {...getRootProps()}>
          <input {...getInputProps({})} />
          <div className="dropzone-content grid justify-items-center gap-5 py-5">
            <RiUploadCloud2Line className="text-6xl text-white" />
            <p className="text-gray-200">
              <span className="font-semibold text-xl">
                Drag & drop files or
              </span>

              <span>
                <button
                  onClick={open}
                  className=" ml-1.5 text-white font-semibold text-xl "
                >
                  Browse
                </button>
              </span>

              <br />
            </p>
            {isDragActive && <p className="dropzone-content">Release to start the analysis</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dropzone;
