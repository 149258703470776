import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "../css/navbar.css";
import { MdLogout } from "react-icons/md";
import AuthContext from "../context/AuthContext";
import ReactTooltip from "react-tooltip";

function Navbar() {
  const { token, logoutUser, user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  function profilSection() {
    return token ? (
      <div className="flex items-center">
        {isOpen ? (
          <button className="ml-2 font-semibold" onClick={logoutUser}>
            <Link to="/" data-tip="Sign out">
              {" "}
              <MdLogout className="text-xl" />{" "}
            </Link>

            <ReactTooltip place="bottom" type="dark" effect="solid" />
          </button>
        ) : (
          <>
            <Link
              to="/account"
              className="border-r-2 border-gray-300 pr-4 mr-4"
            >
              Hello, {user.username}
            </Link>
            <button onClick={logoutUser}>
              <Link to="/" data-tip="Sign out">
                {" "}
                <MdLogout className="text-xl" />{" "}
              </Link>

              <ReactTooltip place="bottom" type="dark" effect="solid" />
            </button>
          </>
        )}
      </div>
    ) : (
      <Link
        to="/signin"
        className="flex items-center bg-nucleon-700 py-2 px-6 text-white font-semibold"
      >
        Sign in
      </Link>
    );
  }
  return (
    <nav className="w-full flex justify-center border-b-2 border-gray-100 px-5">
      <div className="w-full bg-white navbar text-sm max-w-7xl py-4 ">
        <Link to="/" className="relative logo text-xl z-20 ml-5 sm:ml-0">
          <img
            src="/images/malprob.png"
            alt="redshfit logo"
            style={{ height: "3rem" }}
          />
        </Link>
        <div className={`nav-items ${isOpen && "open"} text-center pt-2`}>
          <div className="links font-light">
            <Link to="/openapi" className="nav-link">
              API
            </Link>
            {token && (
              <Link to="/account" className="nav-link">
                Account
              </Link>
            )}
            <Link to="/about" className="nav-link">
              About
            </Link>
            <div className="profil-phone">{isOpen && profilSection()}</div>
          </div>
          <div className="profil-web">{profilSection()}</div>
        </div>
        <div
          className={`nav-toggle ${isOpen && "open"} z-20`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bar after:bg-gray-700 before:bg-gray-700"></div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
