import React from "react";

function FormOptions() {
  return (
    <div>
      <div className="flex items-center justify-between mb-10">
        <div className="flex items-center">
          <input
            id="remember"
            name="remember"
            type="checkbox"
            className="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded"
          />
          <label
            htmlFor="remember"
            className="ml-2 block text-sm text-gray-900"
          >
            Remember me
          </label>
        </div>

        {/*<div className="text-sm">
          <p className="font-medium text-cyan-600 hover:text-cyan-500">
            Forgot your password?
          </p>
        </div>*/}
      </div>
    </div>
  );
}

export default FormOptions;
