import React, { useState, useContext } from "react";
import Header from "../components/Header";
import { signInFields } from "../resources/formFields";
import Input from "../components/Input";
import FormOptions from "../components/FormOptions";
import Submit from "../components/Submit";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import Error from "../components/Error";
import '../css/background.css'

const fields = signInFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

function SignIn() {
  const { loginUser, loginError, setLoginError, load } =
    useContext(AuthContext);
  const [loginState, setLoginState] = useState(fieldsState);

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  return (
    <>
      {load && <Spinner />}
      {loginError && (
        <Error message="Wrong Password or Username" title="Login error" />
      )}
      <div
        className="block lg:grid w-screen min-h-screen"
        style={{ gridTemplateColumns: "3fr 5fr" }}
      >
        <div className="bg-white min-h-screen h-full flex items-center justify-center">
          <div className="bg-white max-w-lg w-full space-y-8 p-10 rounded-3xl">
            <Header>
              <h1 className="text-2xl font-bold text-gray-700 mb-3">
                Sign In to your Account
              </h1>
            </Header>
            <form className="space-y-6" onSubmit={loginUser}>
              <div className="-space-y-px">
                {fields.map((field) => (
                  <Input
                    key={field.id}
                    handleChange={handleChange}
                    value={loginState[field.id]}
                    labelText={field.labelText}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={field.placeholder}
                  />
                ))}
              </div>
              <FormOptions />
              <Submit text="Sign In" />
            </form>
            <p
              className="text-sm text-gray-500"
              style={{ marginBlockStart: "10px" }}
            >
              Don't have an account yet?
              <Link
                onClick={() => {
                  setLoginError(false);
                }}
                to="/signup"
                className="ml-1 font-medium text-nucleon-800/80 hover:text-nucleon-800"
              >
                Sign Up
              </Link>
            </p>
          </div>
        </div>
        <div
          className="grid"
          style={{
            background: `url(
              ${"https://images.unsplash.com/photo-1643135795004-fc2234a62549?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80"}
            )`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="bg w-full h-full">
            <div className="w-full h-full p-12 side text-white grid place-content-center text-center">
              <div className="content grid justify-items-center">
                <h1 className="text-6xl font-bold mb-4">
                  PROACTIVE <br />{" "}
                  <span className="text-nucleon-100">ENDPOINT</span> <br />{" "}
                  PROTECTION
                </h1>
                <p className="text-lg opacity-75 mb-4">
                  AI and Zero-trust powered EDR to stay <br /> ahead of
                  cybercriminals
                </p>
                <a
                  target="_blank"
                  href="https://nucleon-security.com/"
                  className="w-fit bg-nucleon-100/90 drop-shadow-sm text-white text-sm font-semibold rounded-md focus:ring-nucleon-100 focus:border-nucleon-100 block py-3.5 px-7 hover:bg-nucleon-100"
                >
                  More about Nucleon
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
