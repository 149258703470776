import "./App.css";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Report from "./pages/Report";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Account from "./pages/Account";
import AuthContext from "./context/AuthContext";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import Api from "./pages/Api";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookieConsent from "react-cookie-consent";



function PrivateRoute({ children, ..._ }) {
  const { user, loading } = useContext(AuthContext);

  return <>{!user ? <Navigate to="/signin" /> : loading ? null : children}</>;
}

function App() {
  return (
    <>
      <CookieConsent buttonStyle={{ color: "#FFF", backgroundColor: "rgb(48 207 242)", borderRadius: "0.25em" }}>
        Malprob.io uses cookies to improve your browsing experience, 
        by continuing to use this website, you consent to our use of cookies. You can change your cookie settings at any time by adjusting your browser settings
      </CookieConsent>

      <AuthProvider>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/report/:hashcode" element={<Report />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/openapi" element={<Api />} />
          <Route exact path="/account" element={
              <PrivateRoute>
                 <Account />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
