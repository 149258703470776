import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { RedocStandalone } from "redoc";

const baseURL = process.env.REACT_APP_BACKEND_URL;

function Api() {
  return (
    <>
      <div className="flex flex-col w-full min-h-screen">
        <Navbar />
        <div className="grow">
          <RedocStandalone
            specUrl={`${baseURL}/schema`}
            options={{
              nativeScrollbars: false,
              disableSearch: true,
              menuToggle: true,
              onlyRequiredInSamples: true,

              theme: {
                colors: { primary: { main: "#3C499B" } },
                typography: {
                  fontFamily: "Poppins, sans-serif",
                  headings: {
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 700,
                  },
                  code: {
                    fontFamily: "Poppins, sans-serif",
                  }
                },
                rightPanel: {
                  backgroundColor: "#2E0E59",
                },
              },
            }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Api;
