import React, { useContext } from "react";
import { MdOutlineError, MdClear } from "react-icons/md";
import AuthContext from "../context/AuthContext";

function Warning({ message, title = "Error", close }) {
  const { setLoginError } = useContext(AuthContext);
  return (
    <div className="absolute w-screen top-5 flex justify-center z-50">
      <div className="bg-orange-100 flex items-center justify-between p-3 w-96 rounded-xl border-2 border-orange-400">
        <div className="flex items-center gap-2 ">
          <div className="bg-orange-600 p-1.5 rounded-full mr-1">
            <MdOutlineError className="text-2xl text-white" />
          </div>
          <div>
            <p className="text-sm font-semibold text-gray-700">{title}</p>
            <p className="text-xs text-gray-700">{message}</p>
          </div>
        </div>
        <MdClear
          onClick={() => {
            close ? close() : setLoginError(false);
          }}
          className="text-2xl text-orange-600 hover:text-orange-900"
        />
      </div>
    </div>
  );
}

export default Warning;
