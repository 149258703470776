import React from "react";
import { useContext } from "react";
import Background from "../components/Background";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import AuthContext from "../context/AuthContext";
import { changePassword } from "../resources/formFields";
import Submit from "../components/Submit";
import Input from "../components/Input";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Error from "../components/Error";
import Success from "../components/Success";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy, MdCheck } from "react-icons/md";


const fields = changePassword;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

const baseURL = process.env.REACT_APP_BACKEND_URL;

function Account() {
  const { token, logoutUser, user, setLoginError } = useContext(AuthContext);
  const [loginState, setLoginState] = useState(fieldsState);
  const [key, setKey] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("no key found");
  const [success, setSuccess] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  const getKey = () => {
    axios
      .get(`${baseURL}/apiKey/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.access}`,
        },
      })
      .then((response) => {
        setKey(response.data.token);
      })
      .catch((response) => {
        if (response.response.status === 401) {
          logoutUser();
        }
      });
  };

  const changePassword = (event) => {
    event.preventDefault();

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.access}`,
    };

    const formData = new FormData();
    formData.append("old_password", event.target.curretnpassword.value);
    formData.append("new_password", event.target.newpassword.value);

    axios
      .put(`${baseURL}/updatepassword/`, formData, {
        headers: headers,
      })
      .then((response) => {
        if (response.status == 200) {
          setSuccess(true);
          setError(false);
        }
      })
      .catch((response) => {
        setSuccess(false);
        setError(true);
        if (response.response.data.old_password) {
          setMessage(response.response.data.old_password);
        } else if (response.response.data.new_password) {
          setMessage(response.response.data.new_password);
        } else {
          setMessage("Error during the operation");
        }
      });
  };

  useEffect(() => {
    setLoginError(false);
    getKey();
  }, []);
  return (
    <div className="h-screen overflow-y-auto">
      {error && (
        <Error
          title="Password Error"
          message={message}
          close={() => setError(false)}
        />
      )}
      {success && (
        <Success
          title="Password updated succefully"
          message="Your password was updated succefully"
          close={() => setSuccess(false)}
        />
      )}
      <div className="flex flex-col w-full min-h-screen">
        <Navbar />
        <div className="flex flex-col grow">
          <Background styling="w-full p-10 md:px-10 md:py-20 lg:p-20 grid items-center">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-300">
              Hello,{" "}
              <span className="text-white font-bold">{user.username}</span>!
            </h1>
          </Background>
          <div className="grow grid md:grid-cols-2 gap-x-20 gap-y-10 bg-white p-10 lg:p-20">
            <div className="grid gap-10">
              <div>
                <h2 className="text-3xl font-bold text-nucleon-800">
                  Personal information
                </h2>
                <hr className="my-5 border-gray-800" />
                <div className="space-y-6">
                  <div className="-space-y-py">
                    <div className="my-5 max-w-md">
                      <p className="text-md text-gray-500">username</p>
                      <div
                        className={
                          "block mt-1.5 appearance-none w-full max-w-sm py-2 border-b-2 placeholder-gray-400 text-gray-900 focus:outline-none"
                        }
                      >
                        {user.username}
                      </div>
                    </div>
                    <div className="my-5 max-w-md">
                      <p className="text-md text-gray-500">Email</p>
                      <div
                        className={
                          "block mt-1.5 appearance-none w-full max-w-sm py-2 border-b-2 placeholder-gray-400 text-gray-900 focus:outline-none"
                        }
                      >
                        {user.email}
                      </div>
                    </div>
                    <div className="my-5 max-w-md">
                      <p className="text-md text-gray-500">Subscription type</p>
                      <div
                        className={
                          "block mt-1.5 appearance-none w-full max-w-sm py-2 border-b-2 placeholder-gray-400 text-gray-900 focus:outline-none"
                        }
                      >
                        {user.plan}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="text-3xl font-bold text-nucleon-800">Api Key</h2>
                <hr className="my-5 border-gray-800" />
                <div className="max-w-md">
                  <p className="text-gray-500">
                    Use this key wehen useiong the API, also keep this key
                    private
                  </p>
                  <div className="max-w-md">
                    <div
                      className={
                        "flex justify-between items-center mt-1.5 text-sm md:text-md appearance-none w-full max-w-sm py-2 border-b-2 placeholder-gray-400 text-gray-900 focus:outline-none"
                      }
                    >
                      {key}
                      <CopyToClipboard
                        onCopy={() => setCopied(true)}
                        text={key}
                      >
                        <button className="text-lg">
                          {copied ? (
                            <MdCheck className="text-green-500" />
                          ) : (
                            <MdContentCopy className="text-nucleon-800" />
                          )}
                        </button>
                      </CopyToClipboard>
                    </div>
                    {copied && (
                      <span className="text-xs text-green-500">
                        Key copied!
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-3xl font-bold text-nucleon-800">
                Change password
              </h2>
              <hr className="my-5 border-gray-800" />
              <form className="space-y-6 max-w-sm" onSubmit={changePassword}>
                <div className="-space-y-px">
                  {fields.map((field) => (
                    <Input
                      key={field.id}
                      handleChange={handleChange}
                      value={loginState[field.id]}
                      labelText={field.labelText}
                      labelFor={field.labelFor}
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      isRequired={field.isRequired}
                      placeholder={field.placeholder}
                    />
                  ))}
                </div>
                <Submit
                  color="bg-nucleon-800 hover:bg-nucleon-1100  focus:ring-nucleon-800"
                  text="Change password"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Account;
