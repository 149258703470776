const signInFields = [
  {
    labelText: "Email",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Enter your email",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Enter your password",
  },
];

const signUpFields = [
  {
    labelText: "Email",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Enter your email",
  },
  {
    labelText: "Username",
    labelFor: "username",
    id: "username",
    name: "username",
    type: "text",
    autoComplete: "username",
    isRequired: true,
    placeholder: "Choose a username",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "password",
    isRequired: true,
    placeholder: "Enter your password",
  },
];

const changePassword = [
  {
    labelText: "Current Password",
    labelFor: "curretnpassword",
    id: "curretnpassword",
    name: "curretnpassword",
    type: "password",
    autoComplete: "password",
    isRequired: true,
    placeholder: "Enter your current password",
  },
  {
    labelText: "New Password",
    labelFor: "newpassword",
    id: "newpassword",
    name: "newpassword",
    type: "password",
    autoComplete: "newpassword",
    isRequired: true,
    placeholder: "Enter your new password",
  },
];

export { signInFields, signUpFields, changePassword};
